<template>
  <ul class="radio-list">
    <li v-for="(option, indexChoice) in choices" :key="indexChoice">
      <vs-radio v-model="val" :vs-value="option">{{ option }}</vs-radio>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Choices",
  props: {
    choices: {
      Type: Array,
      required: true,
    },
    value: {
      default: null,
    },
    update: {
      type: Function,
    },
  },
  data() {
    return {
      val: "",
    };
  },
  beforeMount() {
    if (this.value) {
      this.val = `${this.value}`;
    }
  },
  watch: {
    val(newVal) {
      this.$emit("change", true);
      this.$emit("update:value", newVal);
      this.update(this.number);
    },
  },
};
</script>

<style>
</style>